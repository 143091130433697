import { http } from "@/utils/http";

class AppService{
    getProducts(){
        return http.get('/products');
    }

    pay(parmas: {
        oldKey?: string;
        email: string;
        count: number;
    }){
        return http.get('/pay', {
            params: parmas
        });
    }
}

export default new AppService();