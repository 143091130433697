<template>
  <router-view/>
</template>

<style lang="scss">
html, body{
  margin: 0;
}
*{
  box-sizing: border-box;
}
</style>
