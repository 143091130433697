<script lang="ts" setup>
import { ref } from 'vue';
import demoJson from '@/assets/demo.json';
import { onMounted } from 'vue';
import appService from '@/services/app-service';
import { Loading, Snackbar } from '@varlet/ui';

const showPayDialog = ref(false);
const showQrcodeDialog = ref(false);
const oldKey = ref('');
const email = ref('');
const emailConfrim = ref('');
const count = ref();
const isLoadingProducts = ref(false);
const isLoadingPay = ref(false);
const products = ref<any[]>([]);
const qrcode = ref('');

const code = ref(`
/**
 * 获取角色信息、皮肤信息
 * [key] 密钥
 * [userId] 营地ID
 * [areaId] 大区ID 1~4 
 *          1-手Q安卓 
 *          2-手Q苹果 
 *          3-安卓微信 
 *          4-苹果微信
 * [mode] 可选 offlineTime | gameLevel
 *        offlineTime (根据离线时间倒序排序取第一个角色)
 *        gameLevel (根据角色等级倒序排序取第一个角色)
 */
const res = await axios.get('${location.origin}/api/wzyd?key=KEY&userId=营地ID&areaId=大区ID');

console.log(res.data);

// response
{
    code: 200, //200时代表请求成功; 303代表key已失效; 其他code见msg字段
    data: {
        user: {...}, // 用户信息
        role: {...}, // 角色信息
        detail: {...}, // 角色的英雄与皮肤信息
    }
}
`.trim());

const download = ()=>{
    // 创建隐藏的可下载链接
    var eleLink = document.createElement('a');
    eleLink.download = 'data.json';
    eleLink.style.display = 'none';
    // 字符内容转变成blob地址
    var blob = new Blob([JSON.stringify(demoJson, null, 2)]);
    eleLink.href = URL.createObjectURL(blob);
    // 触发点击
    document.body.appendChild(eleLink);
    eleLink.click();
    // 然后移除
    document.body.removeChild(eleLink);
}

const openPayDialog = async ()=>{
    showPayDialog.value = true;
    isLoadingProducts.value = true;
    try {
        const res = await appService.getProducts();    
        products.value = res.data.data;
    } catch (error) {
        console.error(error);
    }
    isLoadingProducts.value = false;
}

const onPayDialogBeforeClose = async (action: any, done: any)=>{
    if(action != 'confirm'){
        done();
        return;
    }

    if(!(count.value > 0)){
        Snackbar.error('请选择次数');
        return;
    }
    if(!email.value.trim()){
        Snackbar.error('请输入邮箱');
        return 
    }
    if(email.value != emailConfrim.value){
        Snackbar.error('两次邮箱输入的不一致');
        return;
    }

    if(isLoadingPay.value){
        return;
    }

    isLoadingPay.value = true;
    try {
        const res = await appService.pay({
            oldKey: oldKey.value.trim(),
            count: count.value,
            email: email.value,
        });
        if(res.data?.data?.url_qrcode){
            qrcode.value = res.data.data.url_qrcode;
            showQrcodeDialog.value = true;
            done();
        }else{
            Snackbar.error(res.data?.msg || '未知错误');
        }
    } catch (error) {
        Snackbar.error('未知错误');
    }
    isLoadingPay.value = false;
}

const start = ()=>{
    const dom = document.getElementById('start');
    dom?.scrollIntoView({});
}
</script>

<template>
    <div class="home-page">
        <div class="banner">
            <div class="top">
                <div class="title">王者地壳</div>
                <div class="desc">王者营地 / 角色 / 皮肤 / API</div>
                <div class="desc">仅供学习交流，切勿违法用途</div>
            </div>
            <div class="button">
                <var-space :size="20">
                    <var-button size="large" @click="openPayDialog">获取KEY</var-button>
                    <var-button type="primary" size="large" @click="start">快速开始</var-button>
                </var-space>
            </div>
        </div>
        <div class="guide container" id="start">
            <div class="title">快速开始</div>
            <pre><code class="language-js">{{code}}</code></pre>
            <var-button @click="download">下载Response样本</var-button>
        </div>

        <div class="container" style="text-align: center; padding: 20px">
            2022-2024 ©️ ioshell@126.com 电报群@wzyd
        </div>

        <var-dialog
            title="赞助网站"
            v-model:show="showPayDialog"
            :width="600"
            @before-close="onPayDialogBeforeClose"
            :close-on-click-overlay="false"
        >
            <div style="margin-bottom:10px;">
                <div>1. Key可以不填。不填代表生成新的Key; 填写代表给Key进行充值</div>
                <div>2. Key通过邮箱发放，确保邮箱真实有效！填写错误概不退款</div>
            </div>

            <var-input placeholder="Key（可选。填写后可对Key进行充值；否则生成新Key）" clearable v-model="oldKey" />
            <var-select placeholder="有效次数" v-model="count">
                <var-option v-for="(item, idx) in products" :key="idx" :label="`${item.name} (¥${item.money})`" :value="item.count" />
            </var-select>
            <var-input placeholder="收货邮箱" clearable v-model="email" />
            <var-input placeholder="再次输入收货邮箱" clearable v-model="emailConfrim" />
        </var-dialog>

        <var-dialog
            title="微信支付"
            v-model:show="showQrcodeDialog"
            :width="500"
            :cancel-button-text="'取消'"
            :confirm-button-text="'已完成支付'"
            :close-on-click-overlay="false"
        >
            <div style="margin-bottom:10px;">
                <div>二维码有效期1分钟，请尽快支付</div>
                <div>此页面不会刷新，支付成功后请通过邮箱查收{{ email }}</div>
            </div>

            <div style="text-align: center;margin-top: 20px;">
                <img style="width: 220px;height: 220px;" :src="qrcode"/>
            </div>
        </var-dialog>
    </div>
</template>

<style lang="scss" scoped>
.home-page{
    .banner{
        padding: 40px 20px;
        width: 100%;
        height: 100vh;
        min-height: 600px;
        max-height: 1000px;
        background-color: #e5e5f7;
        opacity: 0.8;
        background-image: radial-gradient(#444cf7 0.5px, #e5e5f7 0.5px);
        background-size: 10px 10px;
        text-align: center;
        display: flex;        
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .top{
            .title{
                font-size: 48px;
                font-weight: bold;
                margin-bottom: 20px;
            }
            .desc{
                color: #011440;
                margin-top: 4px;
            }
        }
        .button{
            margin-top: 80px;
        }
    }

    .guide{
        padding: 60px 20px;
        .title{
            font-size: 16px;
        }
    }

    .container{
        max-width: 1000px;
        width: 100%;
        margin: auto;
    }

    .qrcode{
        width: 100px;
        height: 100px;
        background-color: red;
    }
}
</style>